exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infant-tsx": () => import("./../../../src/pages/infant.tsx" /* webpackChunkName: "component---src-pages-infant-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-service-fertility-tsx": () => import("./../../../src/pages/service/fertility.tsx" /* webpackChunkName: "component---src-pages-service-fertility-tsx" */),
  "component---src-pages-service-pregnancy-tsx": () => import("./../../../src/pages/service/pregnancy.tsx" /* webpackChunkName: "component---src-pages-service-pregnancy-tsx" */),
  "component---src-pages-service-remedial-tsx": () => import("./../../../src/pages/service/remedial.tsx" /* webpackChunkName: "component---src-pages-service-remedial-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-voucher-tsx": () => import("./../../../src/pages/voucher.tsx" /* webpackChunkName: "component---src-pages-voucher-tsx" */)
}

